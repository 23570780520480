import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel, Grid, LinearProgress,
  MenuItem,
  Select, Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import axios from "axios";
import {format, parseISO} from "date-fns";
import PropTypes from "prop-types";
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import { getApiUrl }       from '../app/functions';
import {setGlobalAlertData} from "../app/slices/miscSlice";
import * as API from "../app/utils/ApiFetch";

import ConfirmDialog from "./ConfirmDialog";
import MultiSelectList from "./MultiSelectList";

import {DatePickerWrapper} from "./index";

import './Havariemeldung.css';

const zeiten = [
  '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30',
  '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30',
  '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
  '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
  '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
  '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
];

const containerStyle = {
  display: 'flex',
  marginBottom: '25px',
  padding: '5px',
  border: '1px solid #ddd',
  marginRight: '5px',
  backgroundColor: 'white',
};

const mainBoxStyle = {
  backgroundColor: '#ddd',
  background: 'linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%)',
  border: '1px solid #aaa',
  borderRadius: '5px',
  width: '670px',
};
const buttonBoxStyle = {
  marginLeft: '5px',
  padding: '10px 10px 5px 5px',
  border: '1px solid #ddd',
  borderRadius: '5px',
};
const titleStyle = {
  width: '650px',
  marginBottom: '2px',
};

const meldungsTextStyle = {
  width: '650px',
  marginTop: 0,
};

const hinweisConfig = {
  show: true,
  title: 'Achtung',
  subtitle: 'XXXXXXXXX',
};

const alertConfig = {
  show: true,
  title: 'Achtung',
  subtitle: 'XXXXXXXXX',
};

const checkSoundFileExists = async (url) => {
  try {
    const response = await axios.head(url);
    return response.status === 200;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return false;
    }
    throw error;
  }
};

const Havariemeldung = ({id, meldungData, onDelete}) => {

  const dispatch = useDispatch();
  const audioRef = useRef(null);

  const globalAppData = useSelector((state) => {return state.misc.globalAppData;});

  const [idMeldung, setIdMeldung] = useState();
  const [unternehmen, setUnternehmen] = useState();
  const [title, setTitle] = useState();
  const [meldungsText, setMeldungsText] = useState();
  const [fromDate, setFromDate] = useState();
  const [tillDate, setTillDate] = useState();
  const [zeitVon, setZeitVon] = useState('00:00');
  const [zeitBis, setZeitBis] = useState('00:00');
  const [checkAnrufbeantworter, setCheckAnrufbeantworter] = useState(false);
  const [checkInternet, setCheckInternet] = useState(false);
  const [checkAktiv, setCheckAktiv] = useState(false);
  const [anrufbeantworter, setAnrufbeantworter] = useState([]);
  const [selectedAnrufbeantworter, setSelectedAnrufbeantworter] = useState([]);
  const [saved, setSaved] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [soundFile, setSoundFile] = useState();
  const [textlen, setTextlen] = useState();
  const [opacity, setOpacity] = useState(1);
  const [showSoundCreate, setShowSoundCreate] = useState(false);

  const fetchSelectedAnrufbeantworter = (id) => {
    const path = "/meldung/" + id + "/anrufbeantworter";
    API.GET(path)
        .then((data) => {

          const ids = data._embedded.map((ab) => {
            return ab.id;
          });

          setSelectedAnrufbeantworter(ids);

        })
        .catch((error) => {
          alertConfig.subtitle = "Fehler beim Laden der Anrufbeantworter - " + error.code;
          dispatch(setGlobalAlertData(alertConfig));
        });
  };

  const fetchAnrufbeantworter = (unternehmen) => {
    API.GET('/anrufbeantworter', {'filter[unternehmen]': unternehmen})
        .then((data) => {
          setAnrufbeantworter(data._embedded);
        })
        .catch((error) => {
          alertConfig.subtitle = "Fehler beim Laden der Anrufbeantworter - " + error.code;
          dispatch(setGlobalAlertData(alertConfig));
        });
  };


  const unbindAnrufbeantworter = (id) => {
    const newData = {"anrufbeantworterId": []};

    const path = `/meldung/${id}/anrufbeantworter`;

    API.PUT(path, newData)
        .then(() => {
          console.log('true');
          return true;
        })
        .catch((error) => {
          return error;
        });
  };
  const rebindAnrufbeantworter = async (id) => {

    const newData = {"anrufbeantworterId": selectedAnrufbeantworter};

    const path = `/meldung/${id}/anrufbeantworter`;

    API.PUT(path, newData)
        .then(() => { })
        .catch((error) => {
          console.log(error);
          alertConfig.subtitle = "Fehler beim Speichern der Anrufbeantworter - " + error.code;
          dispatch(setGlobalAlertData(alertConfig));
        });

  };

  const publishInternetAnzeige = () => {

    // const currentMinute = new Date().getMinutes();
    // const mo = ( currentMinute % 5 );

    const postdata = {"apiSecret": `${process.env.REACT_APP_API_SECRERT}`};

    API.POST('/api-key', postdata)
        .then( (result) => {
          API.POST('/' + result.apiKey + '/publish/meldung')
              .then( () => {  })
              .catch( (error) => { console.log(error); });

        })
        .catch( (error) => { console.log(error);});
  };

  const publishSoundFiles = () => {

    const currentMinute = new Date().getMinutes();
    const mo = ( currentMinute % 5 );

    if( mo == 0 || mo == 4 ) {return;}

    const postdata = {"apiSecret": `${process.env.REACT_APP_API_SECRERT}`};

    API.POST('/api-key', postdata)
        .then( (result) => {
          API.POST('/' + result.apiKey + '/publish/soundfile')
              .then( () => {  })
              .catch( (error) => { console.log(error); });

        })
        .catch( (error) => { console.log(error);});
  };

  const saveSelectedAnrufbeantworter = (id) => {
    const path = `/meldung/${id}/anrufbeantworter`;

    const postdata = {"anrufbeantworterId": selectedAnrufbeantworter};

    API.POST(path, postdata)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          alertConfig.subtitle = "Fehler beim Speichern der Anrufbeantworter - " + error.code;
          dispatch(setGlobalAlertData(alertConfig));
        });
  };

  const handleChangeSelectedAnrufbeantworter = (newValue) => {
    setSelectedAnrufbeantworter(newValue);
  };

  const handleChangeFromDateTime = (newValue) => {
    if (newValue.length === 10) {
      setFromDate(parseISO(newValue));
    }
  };

  const handleChangeTillDate = (newValue) => {
    if (newValue.length === 10) {
      setTillDate(parseISO(newValue));
    }
  };

  const handleCheckInternet = (event) => {
    setCheckInternet(event.target.checked);
  };

  const handleCheckAnrufbeantworter = (event) => {
    setCheckAnrufbeantworter(event.target.checked);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleChangeMeldungstext = (event) => {
    setMeldungsText(event.target.value);
    setTextlen(event.target.value.length);
  };

  const handleCheckAktiv = (event) => {
    if (event.target.checked === true) {
      if (checkInternet === false && checkAnrufbeantworter === false) {
        hinweisConfig.subtitle = "Bitte wählen Sie zuerst Anrufbeantworter oder Internet";
        dispatch(setGlobalAlertData(hinweisConfig));
        setCheckAktiv(false);
        return;
      }
      setCheckAktiv(true);
      checkToSave();
    } else {
      setCheckAktiv(false);
      checkToSave();
    }
  };

  React.useEffect(() => {
  }, [showSoundCreate]);
  React.useEffect(() => {
  }, [selectedAnrufbeantworter]);

  React.useEffect(() => {
  }, [soundFile]);

  React.useEffect(() => {
    checkToSave();
  }, [
    selectedAnrufbeantworter, checkAnrufbeantworter, meldungsText,
    title, tillDate, fromDate, title, zeitVon, zeitBis,
  ]);

  React.useEffect(() => {
    if (!idMeldung) {
      setUnternehmen(globalAppData.unternehmen);
      fetchAnrufbeantworter(globalAppData.unternehmen);
    }
  }, [globalAppData]);

  React.useEffect(() => {

    if (id ?? '' !== '') {

      setIdMeldung(id);
      setMeldungsText(meldungData.meldungText);
      setTitle(meldungData.meldungTitle);
      setFromDate(parseISO(meldungData.meldungGueltigAb));
      setTillDate(parseISO(meldungData.meldungGueltigBis));
      setZeitVon(format(new Date(meldungData.meldungGueltigAb), 'HH:mm'));
      setZeitBis(format(new Date(meldungData.meldungGueltigBis), 'HH:mm'));
      setCheckAktiv(meldungData.istAktiv);
      setCheckInternet(meldungData.istInternet);
      setCheckAnrufbeantworter(meldungData.istAnrufbeantworter);
      setUnternehmen(meldungData.unternehmen);
      if (meldungsText) {setTextlen(meldungsText.length);}

      const url = `${getApiUrl()}`
          + "/soundfile/" + meldungData.id;

      fetchSelectedAnrufbeantworter(id);

      checkSoundFileExists(url)
          .then((exists) => {
            setSoundFile(exists === true ? url : null);
            audioRef.current.load();
          })
          .catch(() => {

          });
    } else {
      if (globalAppData.unternehmen !== '...') {setUnternehmen(globalAppData.unternehmen);}
    }
  }, []);

  const checkToSave = () => {
    const von = fromDate;
    const [hoursvon, minutesvon] = zeitVon.split(':').map(Number);
    if (von) {
      von.setHours(hoursvon);
      von.setMinutes(minutesvon);
    }
    const bis = tillDate;
    const [hoursbis, minutesbis] = zeitBis.split(':').map(Number);
    if (bis) {
      bis.setHours(hoursbis);
      bis.setMinutes(minutesbis);
    }

    if (
        !title || title.length < 10 ||
        !meldungsText || meldungsText.length < 100 ||
        !tillDate || !fromDate || !zeitVon || !zeitBis ||
        (checkAnrufbeantworter && selectedAnrufbeantworter.length === 0) ||
        !(von < bis)
    ) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  };

  const deleteMeldung = async () => {
    setShowConfirm(false);

    if (selectedAnrufbeantworter.length > 0) {
      const aError = unbindAnrufbeantworter(idMeldung);
      console.log(aError);
      if (aError !== true) {
        alertConfig.subtitle = "Löschen funktioniert nicht - die Anrufbeantworter konnten nicht entfernt werden - "
            + aError.code;
        dispatch(setGlobalAlertData(alertConfig));
        return false;
      }
    }
    setOpacity(0.2);

    setTimeout(() => {

      API.DELETE('/meldung/' + idMeldung)
          .then((result) => {
            console.log('löschen');
            console.log(result);
            setIdMeldung(null);
            onDelete(idMeldung);
          })
          .catch((error) => {
            alertConfig.subtitle = "Fehler beim Löschen - " + error.code;
            dispatch(setGlobalAlertData(alertConfig));
          });

    }, 800); // 1000ms = 1 Sekunde
  };

  const questionForDelete = () => {
    const result = confirm('löschen');
    if (result === true) {
      deleteMeldung();
    }
  };

  const createSoundFile = async (id) => {
    setShowSoundCreate(true);
    API.POST('/soundfile/' + id)
        .then(() => {

          const apiUrl = getApiUrl();
          const url = apiUrl + "/soundfile/" + id;

          checkSoundFileExists(url)
              .then(() => {
                const dd = new Date().getTime().toString();
                setShowSoundCreate(false);
                setSoundFile(url + "?" + dd);
                audioRef.current.load();

                // hinweisConfig.subtitle='SoundFile erstellt';
                // dispatch(setGlobalAlertData(hinweisConfig));

              })
              .catch(() => {
                setSoundFile();
              });

        })
        .catch((error) => {
          console.log('soundFile not created');
          setShowSoundCreate(false);
          alertConfig.subtitle = "Fehler beim Erstellen des SoundFiles - " + error.code;
          console.log(error);
          dispatch(setGlobalAlertData(alertConfig));
        });

  };

  const saveMeldung = async () => {

    if (checkAktiv === true && checkInternet === false && checkAnrufbeantworter === false) {
      hinweisConfig.subtitle = "Bitte wählen Sie zuerst Anrufbeantworter oder Internet";
      dispatch(setGlobalAlertData(hinweisConfig));
      return;
    }

    const data = {
      "unternehmen": unternehmen,
      "meldungTitle": title,
      "meldungText": meldungsText,
      "meldungGueltigAb": format(fromDate, 'yyyy-MM-dd') + " " + zeitVon + ":00",
      "meldungGueltigBis": format(tillDate, 'yyyy-MM-dd') + " " + zeitBis + ":00",
      "istAnrufbeantworter": checkAnrufbeantworter,
      "istInternet": checkInternet,
      "istAktiv": checkAktiv,
    };

    if (idMeldung) {
      API.PATCH('/meldung/' + idMeldung, data)
          .then(() => {
                rebindAnrufbeantworter(idMeldung);
                setSaved(true);
                createSoundFile(idMeldung);
              },
          )
          .catch((error) => {
            alertConfig.subtitle = "Fehler beim speichern - " + error.code;
            console.log(error);
            dispatch(setGlobalAlertData(alertConfig));
          });
    } else {
      API.POST('/meldung', data)
          .then((result) => {
                setIdMeldung(result.id);
                setSaved(true);
                if (selectedAnrufbeantworter.length > 0) {
                  saveSelectedAnrufbeantworter(result.id);
                }
                createSoundFile(result.id);
              },
          )
          .catch((error) => {
            alertConfig.subtitle = "Fehler beim speichern - " + error.code;
            console.log(error);
            dispatch(setGlobalAlertData(alertConfig));
          });
    }

    publishSoundFiles();
    publishInternetAnzeige();

    return true;
  };

  return (
      <>
        {showConfirm &&
            <ConfirmDialog confirmMessage={"löschen"} onConfirmYes={deleteMeldung}/>
        }
        <Box sx={containerStyle}
             className="box"
             style={{opacity: `${opacity}`}}
        >
          <Box sx={mainBoxStyle}>
            <TextField
                id={"title"}
                placeholder="Überschrift ( min. 10 Zeichen, max 2 Zeilen )"
                sx={titleStyle}
                inputProps={{style: {fontSize: 26, color: '#444', lineHeight: '30px', height: '60px'}}}
                multiline
                maxRows={2}
                value={title}
                onChange={handleChangeTitle}
            />
            <TextField
                id={"meldungstext"}
                placeholder="Meldungstext ( min. 100 Zeichen )"
                sx={meldungsTextStyle}
                multiline
                maxRows={4}
                inputProps={{style: {overflow: 'auto', fontSize: 18, color: '#444', height: '240px'}}}
                value={meldungsText}
                onChange={handleChangeMeldungstext}
            />
            <Grid container justifyContent="right" style={{marginTop: '10px'}}>
              <Grid item xs={8}>
                <audio
                    style={{marginLeft: '10px', opacity: typeof soundFile != 'undefined' ? 1 : 0.1}}
                    controls controlsList="nodownload" ref={audioRef}>
                  <source src={soundFile}/>
                </audio>
                <div style={{minHeight: '6px'}}>
                  {showSoundCreate &&
                      <LinearProgress color="success" sx={{marginLeft: '20px', width: '280px'}}/>
                  }
                </div>
              </Grid>

              <Grid item xs={4}>
                <Stack direction="row" spacing={2} style={{marginTop: '15px'}}>
                  <Button
                      disabled={saved}
                      clor={"secondary"}
                      size="small"
                      startIcon={<SaveIcon/>}
                      onClick={saveMeldung}
                      variant="contained"
                  > speichern</Button>
                  <Button
                      style={{marginLeft: '5px', marginRight: '10px'}}
                      clor={"secondary"}
                      size="small"
                      startIcon={<DeleteIcon/>}
                      onClick={questionForDelete}
                      disabled={!idMeldung}
                      variant="contained"
                  >löschen</Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{fontSize: '10px', position: 'relative', top: '0px', marginLeft: '10px'}}>
                  {unternehmen} {idMeldung} ({textlen})
                </Typography></Grid>
            </Grid>
          </Box>

          <Box sx={buttonBoxStyle}>
            <Typography
                style={{marginLeft: '8px', fontSize: '10pt'}}
                variant={'body1'}>gültig: Datum und Uhrzeit von /
              bis</Typography>

            <div style={{display: 'flex'}}>
              <DatePickerWrapper
                  id={"datum_von"}
                  style={{width: '160px', padding: '-50 12px 0 0', margin: '-5px 0 0 0'}}
                  withPast={true}
                  withWeekend={true}
                  value={fromDate}
                  onChange={handleChangeFromDateTime}
              />
              <FormControl style={{width: '110px', marginTop: '-5px', marginLeft: '3px'}}>
                <Select
                    id="selZeitVon"
                    onChange={(event) => {
                      setZeitVon(event.target.value);
                    }}
                    value={zeitVon}
                >
                  {zeiten && zeiten.length > 0 &&
                      zeiten.map((zeit) => {return (
                          <MenuItem key={zeit} value={zeit}>{zeit}</MenuItem>
                      );})}
                </Select>
              </FormControl>
            </div>

            <div style={{display: 'flex'}}>
              <DatePickerWrapper
                  id={"datum_bis"}
                  style={{width: '160px', padding: '-50 12px 0 0', margin: '-5px 0 0 0'}}
                  withPast={true}
                  withWeekend={true}
                  value={tillDate}
                  onChange={handleChangeTillDate}
              />
              <FormControl style={{width: '110px', marginTop: '-5px', marginLeft: '3px'}}>
                <Select
                    id="selZeitBis"
                    onChange={(event) => {
                      setZeitBis(event.target.value);
                    }}
                    value={zeitBis}
                >
                  {zeiten && zeiten.length > 0 &&
                      zeiten.map((zeit) => {return (
                          <MenuItem key={zeit} value={zeit}>{zeit}</MenuItem>
                      );})}
                </Select>
              </FormControl>

            </div>

            <FormControlLabel
                style={{marginLeft: '3px'}}
                control={
                  <Switch
                      color="success"
                      checked={checkAktiv}
                      onChange={handleCheckAktiv}
                  />
                }
                label={"aktivieren"}
            />

            <FormControlLabel
                style={{marginLeft: '30px'}}
                control={
                  <Switch
                      color="info"
                      variant="solid"
                      checked={checkInternet}
                      onChange={handleCheckInternet}
                  />
                }
                label={"im Internet anzeigen"}
            />

            <FormControlLabel
                style={{marginLeft: '30px'}}
                control={
                  <Switch
                      color="info"
                      checked={checkAnrufbeantworter}
                      onChange={handleCheckAnrufbeantworter}
                  />
                }
                label={"Anrufbeantworter"}
            />

            <MultiSelectList
                style={{marginLeft: '50px', height: '180px', overflow: 'auto'}}
                items={anrufbeantworter}
                keyField={"id"}
                optionField={"bezeichnung"}
                onChange={handleChangeSelectedAnrufbeantworter}
                preSelectedItems={selectedAnrufbeantworter}
                width={"500px"}
            />
          </Box>
        </Box>

      </>
  );
};

Havariemeldung.propTypes = {
  id: PropTypes.string,
  meldungData: PropTypes.object,
  onDelete: PropTypes.func,
};

export default Havariemeldung;